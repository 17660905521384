import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //公共的变量，这里的变量不能随便修改，只能通过触发mutations的方法才能改变
		token:localStorage.getItem('token') || '',
		user:JSON.parse(localStorage.getItem('user')) || {} ,
		equipment:'web'
  },
  getters: {
  },
  mutations: {
	//设置设备信息
	setequipment(state,equipment){
		state.equipment = equipment
		window.localStorage.setItem('equipment',equipment)
	},
    //设置用户信息
	setuser(state,user){
		state.user = user
		window.localStorage.setItem('user',JSON.stringify(user))
	},
	//设置token
	settoken(state,token){
		state.token = token
			window.localStorage.setItem('token',token)
	},
	//退出登录
	userout(state){
		state.token = ''
		state.user = ''
		window.localStorage.setItem('token',{})
		window.localStorage.setItem('token','')
	}
  },
  actions: {
  },
  modules: {
  }
})
