import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/userchildren/login.vue'),
    meta: {
      hideHeader: true, //隐藏头: 真
      hidebottom:true,
    }
  },
  {
    path: '/ProductDetails',
    name: 'ProductDetails',
    component: () => import('../views/ProductDetails/ProductDetails.vue'),
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/user/user.vue'),
    children:[
      {
        path: '/',
        redirect: 'userinfo'
      },
      {
        path: 'userinfo',
        name: 'userinfo',
        component: () => import('../views/userchildren/userinfo.vue'),
      },
      {
        path: 'RecordList',
        name: 'RecordList',
        component: () => import('../views/userchildren/RecordList.vue'),
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
