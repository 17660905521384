import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'

// 导入axios
import axios from 'axios'
Vue.prototype.$http = axios
// 配置请求根路径
axios.defaults.baseURL = 'https://ruiyitang.changgongwang.cn/'
//上传文件
Vue.prototype.$imgFile = 'https://ruiyitang.changgongwang.cn/'+'api/common/upload'
// 配置拦截器
axios.interceptors.request.use(config => {
  config.headers.Token = store.state.token
  return config
})
// 在response拦截器中 隐藏进度条
axios.interceptors.response.use(response => {
  if(response.data.code === 0){
    ElementUI.Message.warning(response.data.msg)
    return Promise.reject(response);
  }
  return response
},error =>{
  if(error.response.data.code === 401){//重新登录
    ElementUI.Message.error('请登录后操作')
    router.replace('/login')
    return
  }else{
    ElementUI.Message.error(error.response.data.msg)
  }
  // Message.error('响应错误')
  return Promise.reject(error);
})
//信息脱敏
Vue.prototype.hideStr = function(str,frontLen,endLen) {
  var len = str.length-frontLen-endLen;
  var xing = '';
  for (var i=0; i<len; i++) {
    xing += '*';
  }
  return str.substring(0,frontLen)+xing+str.substring(str.length-endLen);
}
//添加图片域名
Vue.prototype.$getimgsrc = function (url){
  let urlk = url;
  let str = RegExp('http');
  let newUrl;
  //通过三元运算符进行判断该图片是否含有http域名，没有就拼接上去http://mendian.changgongwang.cn/
  str.test(urlk) ? newUrl = urlk : newUrl = 'https://ruiyitang.changgongwang.cn/' + urlk;
  const urls = newUrl
   return urls
 }


Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
