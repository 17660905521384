<template>
    <div>
        <el-container style="margin-top: 20px;">
            <el-footer style=" height: 282px;">
                <div class="main">
                    <div class="top">
                        <p><i class="el-icon-phone"></i>联系电话：13674128866</p>
                        <p><i class="el-icon-message"></i>联系邮箱：1336563278@qq.com</p>
                    </div>
                    <div class="bottom">
                        <p>联系地址：郑州市管城回族区金城国贸大厦</p>
                    </div>
                </div>
                <div class="main_bottom">
                    <p>
                        Copyright ©2023 版权所有：锐意堂  豫IP12345678945621
                    </p>
                </div>
            </el-footer>
        </el-container>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.el-footer {
    background-color: #2a2d32;
    padding: 0px;
  }
.main{
    text-align: center;
    padding:76px 0px;
    .top{
        display: flex;
        justify-content: space-between;
        margin: auto;
        width: 533+29px;
        color: #dddddd;
        font-size: 16px;
    }
    .bottom{
         color: #dddddd;
          font-size: 16px;
          margin-top: 32px;
    }
}
.main_bottom{
    height: 60px;
    line-height: 60px;
    background-color: #191b1e;
    color: #dddddd;
    font-size: 16px;
    text-align: center;
}
</style>