<template>
  <div >
    <!-- <navheader v-show="!$route.meta.hideHeader"></navheader> -->
    <router-view/>
    <!-- <foote v-show="!$route.meta.hidebottom"></foote> -->
  </div>
</template>


<script>
import navheader from "./components/navheader.vue";
import foote from "./components/foote.vue";
  export default {
    components:{
      navheader,
      foote
    }
  }
</script>


<style>
*{
  padding: 0px;
  margin: 0px;
}
</style>
