<template>
    <div>
        <el-container >
            <el-header class="navBox" style="height: 35px;">
                <div class="navBox_title">
                <span>你好，欢迎光临!</span>
                <router-link :to="{path:'/user'}" class="biaoqian">{{ user.nickname }}</router-link>
                <!-- <a href="" @click="to_login">{{ user.nickname }}</a> -->
                </div>
            </el-header>
        </el-container>
        
    </div>
</template>

<script>
import { mapState } from 'vuex'
    export default {
        data() {
            return {
                
            }
        },
        computed:{
            ...mapState(['user'])
        },
        mounted () {
            
        },
        methods: {
            to_login() {
                this.$router.push({
                    path:'/user'
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
.navBox{
  background-color: #f5f5f5;
  line-height: 35px;
  height: 35px;
  .navBox_title{
    display: flex;
    justify-content: space-between;
    width: 1016px;
    margin: auto;
    font-size: 14px;
    color: #333333;
    .biaoqian{
        text-decoration: none;
        color: #333333;
    }
  }
}
</style>